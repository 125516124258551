<template>
  <div>
    <!-- 图片 -->
    <div class="x-imgs">
      <van-image :src="item" class="x-img" v-for="(item, index) in imgList" :key="index">
        <template v-slot:loading>
          <van-loading type="spinner" size="20" />
        </template>
      </van-image>
    </div>
    <!-- 按钮 -->
    <div class="x-button-dom">
      <van-button type="primary" class="x-button" @click="show = true"
        >前往投保</van-button
      >
    </div>

    <!-- 弹窗 -->
    <van-overlay :show="show" @click="show = false">
      <div class="wrapper">
        <div class="block" @click.stop>
          <div class="x-title">温馨提示</div>
          <div class="x-sbutitle">你即将前往投保，输入经纪人ID，可获得更优质服务</div>
          <input
            type="number"
            v-model="fromData.codeId"
            class="x-inpurt"
            placeholder="请输入经纪人ID"
            oninput="value=value.replace(/[^\d]/g,'')"
          />
          <div class="x-msg">咨询经纪人，即可获得经纪人ID</div>

          <div>
            <van-button round type="primary" class="x-submit" @click="submit"
              >输入完成，立即前往</van-button
            >
          </div>
          <div>
            <van-button round type="primary" class="x-noCode" @click="noCodeSubmit"
              >没有经纪人ID</van-button
            >
          </div>
          <div class="x-clsoe" @click="show = false">
            <img :src="clsoe" />
          </div>
        </div>
      </div>
    </van-overlay>
  </div>
</template>
<script>
import p1 from "../assets/p1.png";
import p2 from "../assets/p2.png";
import p3 from "../assets/p3.png";
import p4 from "../assets/p4.png";
import p5 from "../assets/p5.png";
import clsoe from "../assets/clsoe.png";
import { getSession, setSession } from "../util/sessionStorage";

export default {
  data() {
    return {
      imgList: [p1, p2, p3, p4, p5],
      clsoe,
      codeId: "",
      plantId: "48",
      show: false,
      fromData: {
        codeId: "",
      },
    };
  },
  methods: {
    submit() {
      if (!this.fromData.codeId) {
        this.$Dialog({ message: "经纪人不能为空！" });
        return;
      }
      this.$router.push({ path: "/index/" + this.fromData.codeId });
    },
    noCodeSubmit() {
      setSession("plantId", this.plantId);
      setSession("codeId", this.codeId);
      this.$router.push({ path: "/productDetails/" + this.plantId });
    },
  },
  mounted() {
    // 获取参数
    // codeId 业务员 1025
    // plantId 产品 48

    // /insure?codeId=1024&plantId=48
    document.title = this.$route.name;
    let query = this.$route.query; //置页面ID
    this.codeId = query.codeId || "1025";
    this.plantId = query.plantId || "48";
  },
};
</script>
<style lang="less" scoped>
input {
  outline-color: invert;
  outline-style: none;
  outline-width: 0px;
  border: none;
  border-style: none;
  text-shadow: none;
  -webkit-appearance: none;
  -webkit-user-select: text;
  outline-color: transparent;
  box-shadow: none;
}

.x-imgs {
  padding-bottom: 80px;
  background-color: white;

  .x-img {
    width: 100%;
    vertical-align: middle;
  }
}

.x-button-dom {
  position: fixed;
  bottom: 0px;
  left: 0px;
  right: 0px;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: white;
  padding: 6px 0px;
  padding-bottom: 20px;
  box-sizing: border-box;

  .x-button {
    width: 252px;
    height: 44px;
    background: linear-gradient(136deg, #ffb230 0%, #f76b1c 100%);
    border-radius: 4px;
    font-size: 14px;
    font-weight: 500;
    color: #ffffff;
    margin: 0px auto;
    border: none;
  }
}

.wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.block {
  width: 100%;
  padding: 24px 16px;
  box-sizing: border-box;
  margin: 0px 40px;
  margin-bottom: 50px;
  border-radius: 8px;
  background-color: #fff;
  text-align: center;
  position: relative;

  .x-title {
    font-size: 14px;
    font-weight: bold;
    color: #4d4d4d;
  }

  .x-sbutitle {
    font-size: 14px;
    font-weight: normal;
    color: #666666;
    margin-top: 8px;
  }

  .x-inpurt {
    width: 100%;
    padding: 10px 13px;
    background: #ffffff;
    border-radius: 4px;
    border: 1px solid #e4e4e4;
    font-size: 14px;
    box-sizing: border-box;
    font-weight: 400;
    text-align: center;
    margin-top: 16px;
  }
  .x-inpurt:focus {
    border: 1px solid #ec6941;
  }

  .x-msg {
    font-size: 12px;
    font-weight: normal;
    color: #a6a6a6;
    margin-top: 10px;
  }

  .x-submit {
    width: 100%;
    height: 40px;
    background: linear-gradient(136deg, #ffb230 0%, #f76b1c 100%);
    border-radius: 20px;
    border: none;
    font-size: 14px;
    font-weight: 400;
    color: #ffffff;
    margin-top: 35px;
  }

  .x-noCode {
    width: 100%;
    height: 40px;
    border-radius: 20px;
    border: 1px solid #cccccc;
    background: none;
    font-size: 14px;
    font-weight: 400;
    color: #b3b3b3;
    margin-top: 10px;
  }

  .x-clsoe {
    position: absolute;
    bottom: -49px;
    left: 0px;
    right: 0px;

    img {
      width: 26px;
    }
  }
}
</style>
